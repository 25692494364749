import axios from "axios";
import { useEffect } from "react";
import { createContext, helperForReferences } from "../localeUtils";
const Promise = require("bluebird");
const { Parser } = require("json2csv");

function LocalizePagesDev() {
  const context = require.context("./translated_files", true, /.json$/);

  useEffect(() => {
    (async () => {
      const { jsonFiles } = createContext(context, "Pages");
      const promisesBaseUrl = [];
      const promisesPayload = [];

      const resource_type = await getValues("resource_type");
      const resource_role = await getValues("resource_role");
      const resource_topic = await getValues("resource_topic");

      //loop over all the pages in a file
      for (let fileObj in jsonFiles) {
        let locale = fileObj.split("/")[0];
        const obj = jsonFiles[fileObj];
        const pages = obj["data"];
        pages.forEach((page) => {
          if (page.slug !== "security-advisories") {
            //will remove this condition
            const pageType = page.page_type !== null ? page.page_type : "*";
            const baseURL = `https://api.buttercms.com/v2/pages/${pageType}/${page.slug}/`;

            //the default_template page type has 2 components Resource caraousel and tools and documentation which have references.
            if (pageType === "default_template") {
              if (page.fields.sections) {
                let sections = page.fields.sections;
                sections.forEach((section) => {
                  let fieldType = section.type;
                  delete section.type;
                  if (section.fields.sfs_resources) {
                    section.fields.sfs_resources = helperForReferences(
                      section.fields.sfs_resources,
                      false
                    );
                  }
                  if (section.fields.sfs_admin_tools) {
                    section.fields.sfs_admin_tools.forEach((obj) => {
                      obj.sfs_admin_tools_resources = helperForReferences(
                        obj.sfs_admin_tools_resources,
                        false
                      );
                    });
                  }
                  if (section.hasOwnProperty("fields")) {
                    section[fieldType] = section.fields;
                    delete section.fields;
                  }
                });
              }

              helper(page.fields, resource_role, resource_topic, resource_type);
            }

            //the resources page_type has type, topic and role as references
            if (pageType === "resources") {
              delete page.fields.publish_date;
              helper(page.fields, resource_role, resource_topic, resource_type);
            }

            //for page-type-none file
            if (pageType === "*") {
              if (page.fields.sfs_resources) {
                page.fields.sfs_resources.resources = helperForReferences(
                  page.fields.sfs_resources.resources
                );
              }

              for (const [key, val] of Object.entries(page.fields)) {
                if (Array.isArray(val) && val.length) {
                  const fieldType = val[0].type;
                  delete val[0].type;
                  if (val[0].hasOwnProperty("fields")) {
                    val[fieldType] = val[0].fields;
                    delete val[0].fields;
                  }
                }
              }
            }

            const payload = {
              status: "published",
              fields: {
                [locale]: page.fields,
              },
            };

            promisesBaseUrl.push(baseURL);
            promisesPayload.push(payload);
          }
        });
      }

      console.log(`response: for ${promisesBaseUrl}`);
      console.log("payload ", promisesPayload);

      const errors = [];
      let count = 0;
      Promise.map(
        promisesBaseUrl,
        function (url, index, arraylength) {
          return axios
            .patch(url, promisesPayload[index], {
              headers: {
                Authorization: `Token ${process.env.REACT_APP_CMS_WRITE_KEY_DEV}`,
                "Content-Type": "application/json",
              },
            })
            .then((json) => console.log("success", json))
            .catch((err) => {
              count++;
              console.log("Request Failed", err);
              errors.push({
                URL: url,
                Payload: JSON.stringify(promisesPayload[index]),
                "HTTP Error Message": err.message,
                Response: JSON.stringify(err.response.data),
              });
            });
        },
        { concurrency: 10 }
      )
        .then((json) => {
          console.log(errors);
          const parser = new Parser();
          const csv = parser.parse(errors);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "Page Migration Error Report.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((err) => {
          count++;
          console.log("Request Failed", err);
        });
    })();
  }, []);
}

async function helper(obj, resource_role, resource_topic, resource_type) {
  const { type, topic, role } = obj;
  if (type) {
    for (let i = 0; i < type.length; i++) {
      let devMetaId = helperMetaIdChange(type[i], resource_type);
      if (devMetaId) {
        type[i].meta.id = devMetaId.meta.id;
      } else {
        type.splice(i - 1, 1);
      }
    }
    obj.type = helperForReferences(type, true);
  }
  if (topic) {
    for (let i = 0; i < topic.length; i++) {
      let devMetaId = helperMetaIdChange(topic[i], resource_topic);
      if (devMetaId) {
        topic[i].meta.id = devMetaId.meta.id;
      } else {
        topic.splice(i - 1, 1);
      }
    }
    obj.topic = helperForReferences(topic, true);
  }
  if (role) {
    for (let i = 0; i < role.length; i++) {
      let devMetaId = helperMetaIdChange(role[i], resource_role);
      if (devMetaId) {
        role[i].meta.id = devMetaId.meta.id;
      } else {
        role.splice(i - 1, 1);
      }
    }
    obj.role = helperForReferences(role, true);
  }
}

async function getValues(type, obj) {
  const getBaseURL = `https://api.buttercms.com/v2/content/${type}/?locale=en&auth_token=${process.env.REACT_APP_CMS_READ_KEY_DEV}`;

  let response = axios.get(getBaseURL).then((resp) => {
    return resp.data.data[type];
  });
  return response;
}

function helperMetaIdChange(obj, array) {
  return array.find((item) => item.slug === obj.slug);
}

export default LocalizePagesDev;
