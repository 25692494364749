import React, { useState } from "react";
import LocalizePages from "./Pages/localize-pages";
import LocalizePagesDev from "./Pages/localize-pages-dev";
import LocalizeCollection from "./Collections/localize-collections";
import LocalizeCollectionDev from "./Collections/localize-collections-dev";
import ReadPages from "./localization-team/read-pages-en";
import ReadCollections from "./localization-team/read-collections-en";
import { featureFlagIsUploadAllowed } from "./helpers/feature-flag";
import "./localization-app.scss";

function LocalizationApp() {
  const [localizePagesState, setLocalizePagesState] = useState(false);
  const [localizeCollectionState, setLocalizeCollectionState] = useState(false);
  const [localizeCollectionDevState, setLocalizeCollectionDevState] = useState(false);
  const [localizePagesDevState, setLocalizePagesDevState] = useState(false);
  const [readPagesState, setReadPagesState] = useState(false);
  const [readCollectionsState, setReadCollectionsState] = useState(false);

  const page_types = [{ value: "default_template", label: "Default Template" }, { value: "*", label: "* - Page type 'none' ( Homepage and Security Resources)" }, { value: "blog", label: "Blog" }, { value: "blog_landing_page", label: "Blog Landing page" }];
  const [pages, setPages] = useState({});
  const collection_types = [{ value: "author_blog", label: "Author Blog" }, { value: "blog_topic", label: "Blog topic" }, { value: "footer_menu", label: "Footer Menu" }, { value: "footer_secondary_menu", label: "Footer Secondary Menu" }, { value: "navigation_menu", label: "Navigation Menu" }, { value: "product_collection", label: "Product Collection" }, { value: "resources", label: "Resources" }, { value: "resource_role", label: "Resource Role" }, { value: "resource_topic", label: "Resource Topic" }, { value: "resource_type", label: "Resource Type" }, { value: "security_advisory", label: "Security Advisory" }, { value: "trail_types", label: "Trail Types" }];
  const [collections, setCollections] = useState([]);
  const [pageType, setPageType] = useState("");

  const isUploadAllowed = featureFlagIsUploadAllowed();

  const handlePages = (e) => {
   const { value } = e.target;
   setPages({...pages, [pageType]: value});
  }

  const handleCollections = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // push selected value in list
     setCollections(prev => [...prev, value]);
    } else {
      // remove unchecked value from the list
     setCollections(prev => prev.filter(x => x !== value));
    }
  }

  return (
    <div className="localization-app">
      <header className="localization-header">
        <h1>Localization</h1>
        <div className='title'>Select which Page Types you want translations for ( enter comma seperated slugs)</div>
        <div>
          {page_types.map((page, i) => <label key={i}>
            <input
              type="radio"
              value={page.value}
              name="action"
              onChange={() => setPageType(page.value)}
            /> {page.label}
             <input
              id="slugs"
              onChange={e => handlePages(e)}
            /> 
          </label>)}
        </div>
        <button className="btn" onClick={() => setReadPagesState(true)}>
          Download Selected English Pages
        </button>
           
        <div className='title'>Select which Collections you want translations for:</div>
        <div>
          {collection_types.map((collection, i) => <label key={i}>
            <input
              type="checkbox"
              value={collection.value}
              onChange={e => handleCollections(e)}
            /> {collection.label}
          </label>)}
        </div>
        <button className="btn" onClick={() => setReadCollectionsState(true)}>
          Download Selected English Collections
        </button>

        {isUploadAllowed &&
          <div>
            <button className="btn" onClick={() => setLocalizePagesState(true)}>
              Localizing Pages
            </button>
            <button
              className="btn"
              onClick={() => setLocalizeCollectionState(true)}
            >
              Localizing Collections
            </button>
            <button
              className="btn"
              onClick={() => setLocalizeCollectionDevState(true)}
            >
              Localizing Collections Dev
            </button>  <button className="btn" onClick={() => setLocalizePagesDevState(true)}>
              Localizing Pages Dev
            </button>
          </div>
        }

      </header>
      {readPagesState && <ReadPages pages={pages} />}
      {readCollectionsState && <ReadCollections collection_types={collections} />}
      {localizePagesState && <LocalizePages />}
      {localizeCollectionState && <LocalizeCollection />}
      {localizeCollectionDevState && <LocalizeCollectionDev />}
      {localizePagesDevState && <LocalizePagesDev />}
    </div>
  );
}
export default LocalizationApp;
