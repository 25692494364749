import { useEffect } from "react";
import { getContentData } from "../helpers/api";
import { saveData } from "../localeUtils";
function ReadCollections({collection_types}) {
    useEffect(() => {
        (async () => {
            collection_types.forEach(async type => {
                try {
                    const data = await getContentData(type, {
                        locale: "en",
                    });
                    const fileName = `${type}.json`;
                    saveData(data, fileName);
                } catch (e) {
                    console.error("Error");
                }
            })
        })()
    }, [])
}

export default ReadCollections;