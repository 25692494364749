import axios from "axios";
import { useEffect } from "react";
import { createContext, helperForReferences } from "../localeUtils";
function LocalizePages() {
  const context = require.context("./translated_files_new", true, /.json$/);
  useEffect(() => {
    (async () => {
      const { jsonFiles } = createContext(context, "Pages");
      //loop over all the pages in a file
      for (let fileObj in jsonFiles) {
        let locale = fileObj.split("/")[0];
        const obj = jsonFiles[fileObj];
        const pages = obj["data"];
        if (Array.isArray(pages)) {
          pages.forEach((page) => helperExportPages(page, locale));
        } else {
            helperExportPages(pages, locale);
        }
      }
    })();
  }, []);
}

function helperExportPages(page, locale) {
  const failedSlugs = [];
  const pageType = page.page_type !== null ? page.page_type : "*";
  const baseURL = `https://api.buttercms.com/v2/pages/${pageType}/${page.slug}/`;

  //the default_template page type has 2 components Resource caraousel and tools and documentation which have references.
  if (pageType === "default_template") {
    if (page.fields.sections) {
      let sections = page.fields.sections;
      sections.forEach((section) => {
        let fieldType = section.type;
        delete section.type;
        if (section.fields.sfs_resources) {
          section.fields.sfs_resources = helperForReferences(
            section.fields.sfs_resources,
            true
          );
        }
        if (section.fields.sfs_admin_tools) {
          section.fields.sfs_admin_tools.forEach((obj) => {
            obj.sfs_admin_tools_resources = helperForReferences(
              obj.sfs_admin_tools_resources,
              true
            );
          });
        }
        if (section.fields.sfs_security_advisory) {
          section.fields.sfs_security_advisory.forEach((obj) => {
            obj.product = helperForReferences(
              obj.product,
              true
            );
          });
        }

        for (let [key, val] of Object.entries(section.fields)) {
          if (key !== "sfs_resources") {
            let matchStr = "https://security.salesforce.com"

            if (typeof val === 'string') {
              let index = val.indexOf(matchStr);
              if (index !== -1) {
                val = val.slice(0, index + 31) + `/${locale}` + val.slice(index + 31);
                section.fields[key] = val;
              }

            }
            if (Array.isArray(val)) {
              val.forEach(item => {
                for (let [keyArr, valArr] of Object.entries(item)) {
                  if (typeof valArr === 'string') {
                    let index = valArr.indexOf(matchStr);
                    if (index !== -1) {
                      valArr = valArr.slice(0, index + 31) + `/${locale}` + valArr.slice(index + 31);
                      item[keyArr] = valArr;
                    }
                  }
                }
              })
            }
          }
        }
        if (section.hasOwnProperty("fields")) {
          section[fieldType] = section.fields;
          delete section.fields;
        }
      });
    }

    helper(page.fields);
  }

  //the resources page_type has type, topic and role as references
  if (pageType === "resources") {
    delete page.fields.publish_date;
    helper(page.fields);
  }

  //for page-type-none file
  if (pageType === "*") {
    if (page.fields.sfs_resources) {
      page.fields.sfs_resources.resources = helperForReferences(
        page.fields.sfs_resources.resources
      );
    }

    for (const [key, val] of Object.entries(page.fields)) {
      console.log("val is", val);
      if (Array.isArray(val) && val.length) {
        const fieldType = val[0].type;
        delete val[0].type;
        if (val[0].hasOwnProperty("fields")) {
          val[fieldType] = val[0].fields;
          delete val[0].fields;
        }
      }
    }
  }

  if (pageType === "blog" || pageType === "blog_landing_page") {
    if (page.fields.blog_topics) {
      page.fields.blog_topics = helperForReferences(page.fields.blog_topics, true);
    }
    if (page.fields.featured_tag) {
      page.fields.featured_tag = helperForReferences([page.fields.featured_tag], true)[0];
    }
    if (page.fields.author) {
      page.fields.author = helperForReferences([page.fields.author], true)[0];
    }

    if (page.fields.featured_blog) {
      page.fields.featured_blog = helperForReferences([page.fields.featured_blog], false)[0];
    }

    if (page.fields.blog_components) {
      let blog_components = page.fields.blog_components;
      blog_components.forEach(component => {
        let componentType = component.type;
        delete component.type;
        component[componentType] = component.fields;
      })
    }
  }

  const payload = {
    status: "published",
    fields: {
      [locale]: page.fields,
    },
  };

  console.log(`response: for ${page.slug}`);
  console.log("payload ", payload);

  axios
    .patch(baseURL, payload, {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_CMS_WRITE_KEY_PROD}`,
        "Content-Type": "application/json",
      },
    })
    .then((json) => {
      console.log(`success:${page.page_type}/${page.slug}`, json);
    })
    .catch((json) => {
      failedSlugs.push(page.slug);
      console.log("Failed Slugs array ", failedSlugs);
      console.log(`Failed:${page.page_type}/${page.slug}`, json);
    });

}

function helper(obj) {
  const { type, topic, role } = obj;
  if (type) {
    obj.type = helperForReferences(type, true);
  }
  if (topic) {
    obj.topic = helperForReferences(topic, true);
  }
  if (role) {
    obj.role = helperForReferences(role, true);
  }
}

export default LocalizePages;