import { butterObject } from "./butter";
const butter = butterObject();

export async function getContentData(collectionName, params) {
  try {
    const postResponse = await butter.content.retrieve([collectionName], params);
    return postResponse?.data?.data[collectionName];
  } catch (e) {
    console.error(`Error executing getContentData`, { collectionName, error: e?.response?.data?.detail });
    return e.response;
  }
}

export async function getPageList(listName, params) {
  try {
    const response = await butter.page.list([listName], params);
    return response?.data;
  } catch (e) {
    console.error(`Error executing getPageList`, { listName, error: e?.response?.data?.detail });
    return e.response;
  }
}

export async function getPageData(pageType, pageSlug, params) {
  try {
    const postResponse = await butter.page.retrieve(pageType, pageSlug, params);
    return postResponse?.data?.data;
  } catch (e) {
    console.error(`Error executing getPageData`);
    return e.response;
  }
}
