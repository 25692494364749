import axios from "axios";
import { useEffect } from "react";
import { createContext, helperForReferences } from "../localeUtils";
const Promise = require("bluebird");
const { Parser } = require("json2csv");

function LocalizeCollectionDev() {
  useEffect(() => {
    (async () => {
      let collectionArr = [
        "footer_menu",
        "footer_secondary_menu",
        "navigation_menu",
        "resource_role",
        "resource_topic",
        "resource_type",
      ]; //array of all collection keys
      const enCollection = [];
      const promisesBaseUrl = [];
      const promisesPayload = [];
      for (let i = 0; i < collectionArr.length; i++) {
        const getBaseURL = `https://api.buttercms.com/v2/content/${collectionArr[i]}/?locale=en&auth_token=${process.env.REACT_APP_CMS_READ_KEY_DEV}`;
        let translate = await axios.get(getBaseURL).then((resp) => {
          return resp.data.data[collectionArr[i]];
        });

        enCollection.push({
          collection_key: collectionArr[i],
          translate: translate,
        });
      }

      const context = require.context("./translated_files", true, /.json$/);
      const { jsonFiles } = createContext(context, "Collections");

      for (const fileObj in jsonFiles) {
        const obj = jsonFiles[fileObj];
        let locale = fileObj.split("/")[0];
        const collectionItem = obj["data"];

        for (const [collectionKey, items] of Object.entries(collectionItem)) {
          items.forEach((item, index) => {
            let find = enCollection.find(
              (it) => it.collection_key === collectionKey
            );
            let enItem = {};
            if (
              collectionKey === "footer_menu" ||
              collectionKey === "footer_secondary_menu" ||
              collectionKey === "navigation_menu"
            ) {
              enItem = find.translate.find((it) => it.url === item.url);
              if (collectionKey === "navigation_menu") {
                item.child_items = helperForReferences(item.child_items, true);
              }
            } else {
              if (collectionKey !== "trail_types") {
                enItem = find.translate.find((it) => it.slug === item.slug);
              }
            }
            delete item.meta;
            if (enItem !== undefined) {
              if (Object.keys(enItem).length > 0) {
                const baseURL = `https://api.buttercms.com/v2/content/${collectionKey}/${enItem.meta.id}/`;
                const payload = {
                  status: "published",
                  fields: {
                    [locale]: item,
                  },
                };
                promisesBaseUrl.push(baseURL);
                promisesPayload.push(payload);
              }
            }
          });
        }
      }

      let count = 0;
      const errors = [];
      Promise.map(
        promisesBaseUrl,
        function (url, index, arraylength) {
          return axios
            .patch(url, promisesPayload[index], {
              headers: {
                Authorization: `Token ${process.env.REACT_APP_CMS_WRITE_KEY_DEV}`,
                "Content-Type": "application/json",
              },
            })
            .then((json) => console.log("success ", json))
            .catch((err) => {
              count++;
              console.log("Request Failed", err);
              console.log("error api --", url, promisesPayload[index], count);
              errors.push({
                URL: url,
                Payload: JSON.stringify(promisesPayload[index]),
                "HTTP Error Message": err.message,
                Response: JSON.stringify(err.response.data),
              });
            });
        },
        { concurrency: 10 }
      )
        .then((json) => {
          const parser = new Parser();
          const csv = parser.parse(errors);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
          a.setAttribute("download", "Collection Migration Error Report.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((err) => {
          count++;
          console.log("Request Failed", err);
        });
    })();
  }, []);
}

export default LocalizeCollectionDev;
