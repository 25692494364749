import { useEffect } from "react";
import { getPageData } from "../helpers/api";
import { saveData } from "../localeUtils";
function ReadPages( {pages} ) {
    console.log("pages ",pages)
    useEffect(() => {
        (async () => {
            for (const [page_type, slugsStr] of Object.entries(pages)) {
                let slugArr = slugsStr.split(",");
                slugArr.forEach(slug => fetchPage(page_type, slug, 1))
            }
        })()
    })
}

const fetchPage = async (type, slug, nextPage) => {
    try {
        const data = await getPageData(type, slug, {
            // "fields.is_live": true
            "page": nextPage,
            "locale": 'en',
            "levels": 3
        });
        const fileName = type === "*" ? `page-type-none-${nextPage}.json` : `${type}-${nextPage}.json`;
        saveData(data, fileName);
        /*nextPage = data.meta.next_page;
        if (nextPage !== null)
            fetchPage(type, nextPage);*/
    } catch (e) {
        console.error("Error");
    }
}

export default ReadPages;