import axios from "axios";
import { createContext, helperForReferences } from "../localeUtils";
function LocalizeCollection() {
  const context = require.context("./translated_files_blogs", true, /.json$/);
  const { jsonFiles } = createContext(context, "Collections");
  for (const fileObj in jsonFiles) {
    const obj = jsonFiles[fileObj];
 
    let locale = fileObj.split("/")[0];
    const collectionItem = obj["data"];
    for (const [collectionKey, items] of Object.entries(collectionItem)) {
     
      items.forEach((item) => {
        const baseURL = `https://api.buttercms.com/v2/content/${collectionKey}/${item.meta.id}/`;
        delete item.meta;
        if (collectionKey === "navigation_menu") {
          item.child_items = helperForReferences(item.child_items, true);
        }
        const payload = {
          status: "published",
          fields: {
            [locale]: item,
          },
        };
        console.log("collection key ", collectionKey);
        console.log("payload ", payload);
        axios
          .patch(baseURL, payload, {
            headers: {
              Authorization: `Token ${process.env.REACT_APP_CMS_WRITE_KEY_PROD}`,
              "Content-Type": "application/json",
            },
          })
          .then((json) => console.log("success ", json))
          .catch((err) => console.log("Request Failed", err));
      });
    }
  }
}
export default LocalizeCollection;