//function to add references for pages and collection
export function helperForReferences(inputArray, isCollection) {
  const array = [];
  inputArray.forEach((item) => 
    isCollection ? array.push(item.meta.id) : array.push(item.slug)
  );
  return array;
}

export function createContext(context, folder) {
  const jsonFiles = {};
  let localeFolderName = "";
  console.log("context.keys" ,context.keys());
  context.keys().forEach((key) => {
   
    //split key to fetch locale and file name; for example if key is ./es/Default_Template-2-es.json
    key = key.split("/");
    localeFolderName = key[1]; //es
    const fileName = key[2]; //Default_Template-2-es.json
    const resource = require(`../localization/${folder}/translated_files_new/${localeFolderName}/${fileName}`); // path
    const namespace = fileName.replace(".json", "");
    jsonFiles[localeFolderName+"/"+namespace] = JSON.parse(JSON.stringify(resource));
  });
  return {jsonFiles: jsonFiles};
}

export function saveData(content, fileName) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  let json = JSON.stringify(content),
      blob = new Blob([json], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};